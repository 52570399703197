@mixin button {
    @include no-select;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.375em 0.75em;
    background-color: #fff;
    color: #363636;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0 rgb(218, 218, 218);
    font-size: 0.9rem;
    font-family: inherit;
    height: 2.25em;
    appearance: none;
    border: none;
    -webkit-touch-callout: none;
    &[disabled] {
        box-shadow: none;
        opacity: 0.5;
        cursor: not-allowed;
    }
    @for $i from 1 through 17 {
        &.color-#{$i}:hover:not(:disabled) {
            background: linear-gradient(
                45deg,
                rgba(255, 255, 255, 1) 0%,
                adjust-color(nth($rainbow-17, $i), $alpha: -0.9) 100%
            );
        }
        &.color-#{$i}:active:not(:disabled) {
            background: linear-gradient(
                45deg,
                rgba(255, 255, 255, 1) 0%,
                adjust-color(nth($rainbow-17, $i), $alpha: 0) 100%
            );
            box-shadow: none;
            color: white;
        } 
        &.color-#{$i}.active {
            background: linear-gradient(
                45deg,
                adjust-color(nth($rainbow-17, $i), $lightness: +15) 0%,
                adjust-color(nth($rainbow-17, $i), $lightness: -5) 100%
            );
            color: white;
            &:hover {
                background: linear-gradient(
                    45deg,
                    adjust-color(nth($rainbow-17, $i), $lightness: +25) 0%,
                    adjust-color(nth($rainbow-17, $i), $lightness: -5) 100%
                );
            }
            &:active:not(:disabled) {
                background:   adjust-color(nth($rainbow-17, $i),  $lightness: -10);
                box-shadow: none;
            }
        }
       
    }
}

@mixin text-input {
    // box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    position: relative;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.375em 0.625em;
    max-width: 100%;
    background-color: #fff;
    border: 1.5px solid #aeaeae;
    border-radius: 5px;
    color: #363636;
    font-size: 1rem;
    font-family: inherit;
    height: 2.25em;
    line-height: 1.5;
    vertical-align: top;
    appearance: none;
    box-sizing: border-box;
    &:hover {
        border-color: #858585;
    }
    &:active,
    &:focus {
        border-color: #3273dc;
        box-shadow: 0 0 0 0.2rem rgba(50, 115, 220, 0.25);
        outline: 0;
    }
}

@mixin no-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
