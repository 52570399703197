.modal-background {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: none;
    &.show {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal-container {
        max-width: 42rem;
        max-height: 90vh;
        overflow-y: auto;
        flex-grow: 1;
        background-color: rgb(247, 247, 247);
        box-shadow: 0px 5px 5px 2px rgba(32, 32, 32, 0.2);
        border-radius: 1rem;
        padding: 1rem;
        margin:1rem;
    }
}
