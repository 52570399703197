@use "sass:color";
@use "../base" as *;

.mt-ios {
  @include wrapper;
  label {
    // background
    @include label;
    width: 5em;
    height: 3em;
    border-radius: 1.5em;
    background: #f8f8f8;
    box-shadow: inset 0 0 0 0px rgba(19, 191, 17, 1), 0 0 0 0.1875em #dddddd;
    transition: 0.25s ease-in-out;
    &:before {
      // circle
      @include before-after;
      width: 3em;
      height: 3em;
      border-radius: 1.5em;
      background: #fff;
      box-shadow: 0 0.375em 0.375em rgba(0, 0, 0, 0.2), 0 0 0 0.1875em #dddddd;
      transition: 0.25s ease-in-out;
    }
  }
  input {
    @include input-checkbox;
  }
  input:checked + label {
    // background
    box-shadow: inset 0 0 0 1.5em rgba(19, 191, 17, 1), 0 0 0 0.1875em rgba(19, 191, 17, 1);
    &:before {
      // circle
      transform: translateX(2em);
      box-shadow: 0 0 0 0.1875em transparent, 0 0.375em 0.375em rgba(0, 0, 0, 0.3);
    }
  }
  input:disabled + label {
    // background
    background: #ddd !important;
    box-shadow: inset 0 0 0 0px rgba(19, 191, 17, 1), 0 0 0 0.1875em #ccc !important;
    cursor: not-allowed !important;
    &:before {
      // circle
      background: #eee !important;
      box-shadow: none !important;
    }
  }
}
.mt-ios-red {
  @extend .mt-ios;
  label {
    // background
    box-shadow: inset 0 0 0 0px #f35f42, 0 0 0 0.1875em #dddddd;
  }
  input:checked + label {
    // background
    box-shadow: inset 0 0 0 1.5em #f35f42, 0 0 0 0.1875em #f35f42;
  }
}
.mt-ios-blue {
  @extend .mt-ios;
  label {
    // background
    box-shadow: inset 0 0 0 0px #1fc1c8, 0 0 0 0.1875em #dddddd;
  }
  input:checked + label {
    // background
    box-shadow: inset 0 0 0 1.5em #1fc1c8, 0 0 0 0.1875em #1fc1c8;
  }
}
