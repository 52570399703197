@import "colors";
@import "UI";

.chordSelector-container {
    max-width: 63rem;
    margin: 0.5rem auto;
    background-color: rgb(251, 251, 251);
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 5px;
    input {
        @include text-input;
        width: 100%;
        margin-bottom: 1rem;
        @for $i from 1 through 17 {
            &.color-#{$i}:focus {
                border-color: nth($rainbow-17, $i);
                box-shadow: 0 0 0 0.2em adjust-color(nth($rainbow-17, $i), $alpha: -0.8);
            }
        }
    }
    .chord-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .chord {
            flex-grow: 1;
            padding: 2rem 0.5rem;
            box-sizing: border-box;
            margin: 3px;
            background-color: white;
            text-decoration: none;
            color: black;
            text-align: center;
            border-radius: 5px;
            box-shadow: 0px 0px 5px 0 rgb(233, 233, 233);
            &:hover {
                .name {
                    background: -webkit-repeating-linear-gradient(45deg, #000, #999 20px, #999 20px, #000 30px);
                    background-clip: border-box;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 600;
                }
                @for $i from 1 through 17 {
                    &.color-#{$i} {
                        background: linear-gradient(
                            45deg,
                            rgba(255, 255, 255, 1) 0%,
                            adjust-color(nth($rainbow-17, $i), $alpha: -0.9) 100%
                        );
                        box-shadow: 0px 0px 5px 0 rgb(220, 220, 220);
                    }
                }
            }
            .name {
                font-size: 1rem;
                margin-top: 0.75rem;
            }
        }
        .missing-chord {
            line-height: 1.3rem;
            color: rgb(77, 77, 77);
            a,
            a:link,
            a:active,
            a:visited,
            a:hover {
                color: rgb(124, 124, 124);
            }
            a.no-decoration {
                text-decoration: none;
            }
            b {
                font-weight: bold;
            }
        }
    }
}
